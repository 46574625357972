@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~vanilla-cookieconsent/dist/cookieconsent.css';
/* You can add global styles to this file, and also import other style files */

@layer base {
  p {
    @apply text-xs;
  }
}

html {
  font-size: 16px;
}

/* Causes thick borders randomly */
.outline {
  outline-style: inherit;
}
